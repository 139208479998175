import * as React from "react"
import type { HeadFC } from "gatsby"

const IndexPage = () => {
  return (
    <main>
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>NoBees Candles</title>
